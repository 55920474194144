import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDateDisplayString',
  standalone: true
})
export class ToDateDisplayStringPipe implements PipeTransform {
  /**
   * 
   * @param value date string (typically a timestamp from contentful)
   * @returns a displayable date string
   */
  transform(value: string): string {
    let date = new Date(value);
    return Intl.DateTimeFormat('en-GB', {dateStyle: "medium"}).format(date);
  }

}

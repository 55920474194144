import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toTimeStartEndDisplayString',
  standalone: true
})
export class ToTimeStartEndDisplayStringPipe implements PipeTransform {

  /**
   * Given two date strings, it will return a string like "6pm - 9pm".
   * Used for displaying start and end times for workshops.
   * @param value the start time
   * @param args the end time
   * @returns 
   */
  transform(value: string, ...args: string[]): string {
    let startDateString = value;
    let endDateString = args[0];

    let finalString = "";

    let startDate = new Date(startDateString);
    finalString += Intl.DateTimeFormat('en-GB', {hour12: true, hour: "numeric"}).format(startDate).replace(" ", "") + " - ";

    let endDate = new Date(endDateString);
    finalString += Intl.DateTimeFormat('en-GB', {hour12: true, hour: "numeric"}).format(endDate).replace(" ", "");

    return finalString;
  }

}

import { Component, OnDestroy } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { CardComponent } from '../../shared/card/card.component';
import { NgFor, NgForOf, NgIf } from '@angular/common';
import { RecipeService } from '../../services/recipe.service';
import { Subject, takeUntil } from 'rxjs';
import { IFdinRecipe } from '../../shared/interfaces/contentful';

@Component({
  selector: 'app-recipes',
  standalone: true,
  imports: [PageHeadingComponent, VideoPlayerComponent, SectionHeadingComponent, CardCarouselComponent, CardComponent, NgForOf, NgFor, NgIf],
  templateUrl: './recipes.component.html',
  styleUrl: './recipes.component.scss'
})
export class RecipesComponent implements OnDestroy {

  private componentDestroyed = new Subject<void>();

  public moreRecipesCardsContent: IFdinRecipe[] = [];

  public visibleFeaturedRecipesIndexes: number[] = [];

  public mealTypeCardsContent: {
    imageUrl: string,
    ctaText: string,
    link: string,
  }[] = [
      {
        imageUrl: 'assets/images/breakfast.png',
        ctaText: 'See Breakfast Recipes',
        link: "/recipes/search/breakfast"
      },
      {
        imageUrl: 'assets/images/dinner.png',
        ctaText: 'See Dinner Recipes',
        link: "/recipes/search/dinner"
      },
      {
        imageUrl: 'assets/images/snacks.png',
        ctaText: 'See Snack Recipes',
        link: "/recipes/search/snacks"
      },
      {
        imageUrl: 'assets/images/baking.png',
        ctaText: 'See Baking Recipes',
        link: "/recipes/search/baking"
      },
      {
        imageUrl: 'assets/images/dessert.png',
        ctaText: 'See Dessert Recipes',
        link: "/recipes/search/dessert"
      },
      {
        imageUrl: 'assets/images/lunch.png',
        ctaText: 'See Lunch Recipes',
        link: "/recipes/search/lunch"
      },
      {
        imageUrl: 'assets/images/salads.png',
        ctaText: 'See Salad Recipes',
        link: "/recipes/search/salads"
      }
    ];

  public visibleMealTypesIndexes: number[] = [];

  public foodInnovatorFavouritesCardsContent: IFdinRecipe[] = [];

  public visibleFoodInnovatorFavouritesIndexes: number[] = [];


  constructor(private _RecipeService: RecipeService) {
    this.getRecipes();
    this.getFavourites();
  }

  private getRecipes() {
    this._RecipeService.getAllRecipes(6).pipe(takeUntil(this.componentDestroyed)).subscribe((recipeData) => {

      this.moreRecipesCardsContent = recipeData;

    });
  }

  private getFavourites() {
    this._RecipeService.getFoodInnovatorFavouriteRecipes(6).pipe(takeUntil(this.componentDestroyed)).subscribe((recipeData) => {

      this.foodInnovatorFavouritesCardsContent = recipeData;

    });
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}

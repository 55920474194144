import { Component } from '@angular/core';
import { IFdinArticle } from '../../shared/interfaces/contentful';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from '../../services/article.service';
import { NgFor, NgIf } from '@angular/common';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { ToDateDisplayStringPipe } from "../../shared/pipes/to-date-display-string.pipe";
import { RenderRichTextDirective } from '../../shared/directives/render-rich-text.directive';

@Component({
  selector: 'app-article',
  standalone: true,
  imports: [NgIf, PageHeadingComponent, ToDateDisplayStringPipe, RenderRichTextDirective, NgFor],
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})
export class ArticleComponent {

  public slug?: string; //slug of the article

  articleData?: IFdinArticle;

  public selectedHeroImageUrl?: string; 

  private componentDestroyed = new Subject<void>();


  constructor(private _Activatedroute: ActivatedRoute, private _ArticleService: ArticleService, private _Router: Router) {

  }

  ngOnInit() {
    this.slug = this.getSlugFromRoute();
    this.getArticleData(this.slug);
  }

  /**
* The slug of the article is in the route as a route param.
*/
  getSlugFromRoute(): string {
    // get the route param
    const slugParam = this._Activatedroute.snapshot.paramMap.get('articleSlug');

    if (slugParam) {
      return slugParam;
    }

    this._Router.navigateByUrl('/not-found');
    return '';
  }

  /**
    * Get data for the article.
    *
    * @param articleSlug - slug for the article to return.
    */
  getArticleData(articleSlug: string) {
    this._ArticleService.getArticleBySlug(articleSlug).pipe(takeUntil(this.componentDestroyed)).subscribe((articleData) => {
      if (!articleData) {
        this._Router.navigateByUrl('/not-found');
        return;
      }

      this.articleData = articleData;
      this.selectedHeroImageUrl = articleData.fields.heroImage?.fields.file.url; //pre-select the hero image

    });
  }
}

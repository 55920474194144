<div id="logo-container">
    <a routerLink="/">
        <img src="assets/images/logo-with-shadow.png" id="logo">
    </a>
</div>


<ul id="nav-items">
    <div *ngIf="isAnyMenuItemOpen" class="menu-dimmer" (mouseenter)="closeAllMenuItems()" (click)="closeAllMenuItems()"></div>

    <li *ngFor="let menuItem of menuItems" (click)="openMenuItem(menuItem)">
        <div>
            {{menuItem.text}}
        </div>
        <img src="assets/images/chevron-down.svg" alt="" *ngIf="menuItem.items && menuItem.items.length > 0">

        <ul class="dropdown" *ngIf="menuItem.items && menuItem.items.length > 0 && menuItem.isOpen">
            <li *ngFor="let subItem of menuItem.items" (click)="navigateTo(subItem)">
                <div>{{subItem.text}}</div>
                <img src="assets/images/chevron-right.svg" alt="">
            </li>
        </ul>
    </li>
</ul>

<form id="search-bar">
    <input type="text" [(ngModel)]="searchTerm" name="search">
    <button aria-label="search" (click)="search()">
        <img src="assets/images/search.svg" alt="search">
    </button>
</form>

<div id="mobile-buttons">
    <button aria-label="menu" (click)="toggleMobileMenu()">
        <img src="assets/images/menu.svg" alt="menu">
    </button>
</div>

<div id="mobile-menu" *ngIf="isMobileMenuOpen">
    <ul>
        <li *ngFor="let menuItem of menuItems" (click)="toggleMenuItem(menuItem)">
            <div class="mobile-menu-item">
                <div>{{menuItem.text}}</div>
                <img src="assets/images/chevron-down.svg" alt="" *ngIf="menuItem.items && menuItem.items.length > 0">
            </div>
            <ul class="mobile-accordion" *ngIf="menuItem.items && menuItem.items.length > 0 && menuItem.isOpen">
                <li *ngFor="let subItem of menuItem.items" (click)="navigateTo(subItem)">
                    <div>{{subItem.text}}</div>
                    <img src="assets/images/chevron-right.svg" alt="">
                </li>
            </ul>
        </li>
    </ul>

    <form id="mobile-search-bar">
        <input type="text" [(ngModel)]="searchTerm" name="search">
        <button aria-label="search" (click)="search()">
            <img src="assets/images/search.svg" alt="search">
        </button>
    </form>

    <div id="dimmer"></div>
</div>
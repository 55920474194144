import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { CardComponent } from '../../shared/card/card.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-meet-the-team',
  standalone: true,
  imports: [PageHeadingComponent, CardComponent, NgForOf],
  templateUrl: './meet-the-team.component.html',
  styleUrl: './meet-the-team.component.scss'
})
export class MeetTheTeamComponent {
  public meetTheTeamCardsContent: {
    imageUrl: string,
    name: string,
    title: string
  }[] = [
      {
        imageUrl: "assets/images/team-member.png",
        name: "BAZIL STANDER",
        title: "Executive Chef",

      },
      {
        imageUrl: "assets/images/amanda.png",
        name: "TIFFANY CROUCH",
        title: "Innovation Chef",

      },
      {
        imageUrl: "assets/images/amandanew.png",
        name: "AMANDA KWIEZ",
        title: "Innovation Chef",

      },
      {
        imageUrl: "assets/images/edward.png",
        name: "EDWARD VON DELELIS",
        title: "Innovation Chef",

      },
      {
        imageUrl: "assets/images/charlene.jpeg",
        name: "CHARLENE PRETORIUS",
        title: "Innovation Chef",

      },
      {
        imageUrl: "assets/images/gabrielle.png",
        name: "GABRIELLE GEORGE",
        title: "Innovation Chef / Kitchen Manager",

      },
      {
        imageUrl: "assets/images/paris.png",
        name: "PARIS HAMBRIDGE",
        title: "Junior Innovation Chef",
      },
      {
        imageUrl: "assets/images/jesse.png",
        name: "JESSE HAPUKU",
        title: "Junior Innovation Chef",
      }

    ];
}

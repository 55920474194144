import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-card-search',
  standalone: true,
  imports: [FormsModule, NgIf, AlertComponent],
  templateUrl: './card-search.component.html',
  styleUrl: './card-search.component.scss'
})
export class CardSearchComponent implements OnChanges {
  @Output() matchingCardsEvent = new EventEmitter<any[]>();
  @Input() cards: any[] = [];
  searchTerm: string = "";
  matchingCards: any[] = [];
  showMoreButton = false;
  cardsShownCount = 6;

  constructor() {
  }

  ngOnChanges() {
    this.resetSearch();
  }

  resetSearch() {
    if (!this.searchTerm) {
      this.cardsShownCount = 6;
      this.matchingCards = [];
      this.cards.forEach(card => {
        if (this.matchingCards.length < this.cardsShownCount) {
          this.matchingCards.push(card);
          this.showMoreButton = false;
        } else {
          this.showMoreButton = true;
        }
      });

      this.matchingCardsEvent.emit(this.matchingCards);
    }
  }

  filterResults() {
    this.matchingCards = [];
    this.cards.forEach(card => {
      if (JSON.stringify(card).toLowerCase().includes(this.searchTerm.toLowerCase())) {

        if (this.matchingCards.length < this.cardsShownCount) {
          this.matchingCards.push(card);
          this.showMoreButton = false;
        } else {
          this.showMoreButton = true;
        }
      }
    });

    this.matchingCardsEvent.emit(this.matchingCards);
  }

  increaseShownCount() {
    this.cardsShownCount += 6;
    this.filterResults();
  }
}

<app-page-heading heading="Food Trends" breadcrumbText="{{category}}"></app-page-heading>

<div class="page-body-wrapper">
    <p *ngIf="articles?.length == 0 && !isLoading">There are no articles to show.</p>

    <p *ngIf="isLoading">Loading...</p>

    <ul *ngIf="!isLoading && articles && articles.length > 0">
        <li *ngFor="let article of articles" class="list-card" [routerLink]="'/article/' + article.fields.slug">
            <div class="list-card-image"
                [style.background-image]="'url(' + article.fields.heroImage.fields.file.url + ')'">

            </div>
            <div class="list-card-content">
                <div class="list-card-date">
                    {{article.sys.createdAt | date: 'dd MMM yyyy'}}
                </div>
                <div class="list-card-heading">
                    {{article.fields.title}}
                </div>
                <div class="list-card-body">
                    {{article.fields.summary}}
                </div>
                <button class="list-card-button">
                    Continue Reading
                </button>
            </div>
        </li>
    </ul>
</div>
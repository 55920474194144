<app-page-heading heading="Search results" breadcrumbText="Search results"></app-page-heading>

<div class="page-body-wrapper">

    <div class="page-body-content">

        <div class="search-type">
            <button [class.selected]="selectedSearchType == 'article'"  (click)="changeSelectedSearchType('article')">
                Articles
            </button>

            <button [class.selected]="selectedSearchType == 'recipe'" (click)="changeSelectedSearchType('recipe')">
                Recipes
            </button>

            <button [class.selected]="selectedSearchType == 'resource'" (click)="changeSelectedSearchType('resource')">
                Resources
            </button>

            <button [class.selected]="selectedSearchType == 'workshop'" (click)="changeSelectedSearchType('workshop')">
                Workshops
            </button>
        </div>

        <h3 *ngIf="isLoading">Loading...</h3>

        <h3 *ngIf="!isLoading && results.length == 0">No Results Found</h3>

        <ul *ngIf="!isLoading && results && results.length > 0">
            <a *ngFor="let result of results">
                <li class="list-card">
                    <div class="list-card-image"
                        [style.background-image]="'url(' + result.imageUrl + ')'">
                    </div>
                    <div class="list-card-content">
                        <div class="list-card-heading">
                            {{result.heading}}
                        </div>
                        <div class="list-card-body">
                            {{result.description}}
                        </div>
                        <button class="list-card-button" (click)="openLink(result.link)">
                            View
                        </button>
                    </div>
                </li>
            </a>
        </ul>
    </div>
</div>
import { Injectable } from '@angular/core';
import { ContentfulService } from '../shared/services/contentful.service';
import { IFdinRecipe } from '../shared/interfaces/contentful';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(private cms: ContentfulService) {

  }

  /**
   * Gets a recipe from the CMS.
   *
   * @param slug - slug of the recipe to retrieve.
   * @returns The recipe from the cms.
   */
  getRecipeBySlug(slug: string | number): Observable<IFdinRecipe> {
    return this.cms.getContent('fdinRecipe', { 'fields.slug': slug, include: 10 }).pipe(map((res) => res[0] as IFdinRecipe));
  }

  /**
  * Gets all recipes from the CMS.
  *
  * @param limit how many recipes to fetch, if not provided, all are fetched
  * @returns The recipes from the cms.
  */
  getAllRecipes(limit: number): Observable<IFdinRecipe[]> {
    return this.cms.getContent('fdinRecipe', { include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinRecipe[]));
  }

  /**
* Gets recipes marked as favourite from the CMS.
*
* @param limit how many recipes to fetch, if not provided, all are fetched
* @returns The recipes from the cms.
*/
  getFoodInnovatorFavouriteRecipes(limit: number): Observable<IFdinRecipe[]> {
    return this.cms.getContent('fdinRecipe', { 'fields.isFoodInnovatorsFavourite': 'true', include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinRecipe[]));
  }

  /**
* Search in the CMS
*
* @param searchTerm
* @param limit how many results to fetch, if not provided, all are fetched
* @returns The results from the cms.
*/
  searchRecipes(searchTerm: string, limit: number): Observable<IFdinRecipe[]> {
    return this.cms.getContent('fdinRecipe', { query: searchTerm, include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinRecipe[]));
  }
}

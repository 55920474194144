<app-page-heading *ngIf="articleData" heading="{{articleData.fields.title}}"
    breadcrumbText="Article"></app-page-heading>

<div class="page-body-wrapper" id="article-content" *ngIf="articleData">
    <div class="page-body-content">

        <!-- article header -->
        <div class="article-header">
            <!-- hero image -->
            <div class="page-hero-image"
                [style.background-size]="selectedHeroImageUrl == articleData.fields.heroImage.fields.file.url ? articleData.fields.imageDisplayType : 'contain'"
                [style.background-image]="'url(' + selectedHeroImageUrl + ')' "></div>

            <div class="additional-images"
                *ngIf="articleData.fields.additionalImages && articleData.fields.additionalImages.length > 0">
                <div class="image-thumbnail"
                    [class.selected]="selectedHeroImageUrl == articleData.fields.heroImage.fields.file.url"
                    (click)="selectedHeroImageUrl = articleData.fields.heroImage.fields.file.url"
                    [style.background-image]="'url(' + articleData.fields.heroImage.fields.file.url + ')' ">

                </div>
                <div class="image-thumbnail" [class.selected]="selectedHeroImageUrl == image.fields.file.url"
                    (click)="selectedHeroImageUrl = image.fields.file.url"
                    [style.background-image]="'url(' + image.fields.file.url + ')' "
                    *ngFor="let image of articleData.fields.additionalImages">

                </div>
            </div>

            <h3 id="article-name">{{articleData.fields.title}}</h3>

            <div id="article-bar">
                <div class="bar-item-with-icon">
                    <img src="assets/images/calendar.svg" alt="">
                    <div>{{articleData.sys.createdAt | toDateDisplayString}}</div>
                </div>
            </div>
        </div>

        <!-- article body -->
        <div class="article-body">
            <div class="column" [appRenderRichText]="articleData.fields.content">

            </div>
        </div>
    </div>
</div>
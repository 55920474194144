import { Environment } from './environment.interface';

export const environment: Environment = {
  baseUrl: 'https://cdn.contentful.com',
  contentful: {
    space: '3h8j9vctv5nr',
    apiString: 'wUvQzFyXCw7mLEKHIBtrDi_rkgp_I-oA7rc13DPOj0w',
    contentfulBaseURL: 'https://cdn.contentful.com',
    contentfulPreviewBaseURL: 'https://preview.contentful.com',
    previewApiString: 'z5wKnU9j4u7h-jJfUlBshekMC6hRFx7k9g7LZK35pfo',
    environment: 'master',
  },
  urlToShowPreviewOptions: '34.144.223.194',
};

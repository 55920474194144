<app-page-heading heading="Workshop Details" breadcrumbText="Workshop details"></app-page-heading>

<div class="page-body-wrapper" id="workshop-content" *ngIf="workshopData">
    <div class="page-body-content">

        <!-- workshop header -->
        <div class="workshop-header">
            <!-- hero image -->
            <div class="page-hero-image"
                [style.background-size]="selectedHeroImageUrl == workshopData.fields.image.fields.file.url ? workshopData.fields.imageDisplayType : 'contain'"
                [style.background-image]="'url(' + selectedHeroImageUrl + ')' "></div>

            <div class="additional-images"
                *ngIf="workshopData.fields.additionalImages && workshopData.fields.additionalImages.length > 0">
                <div class="image-thumbnail"
                    [class.selected]="selectedHeroImageUrl == workshopData.fields.image.fields.file.url"
                    (click)="selectedHeroImageUrl = workshopData.fields.image.fields.file.url"
                    [style.background-image]="'url(' + workshopData.fields.image.fields.file.url + ')' ">

                </div>
                <div class="image-thumbnail" [class.selected]="selectedHeroImageUrl == image.fields.file.url"
                    (click)="selectedHeroImageUrl = image.fields.file.url"
                    [style.background-image]="'url(' + image.fields.file.url + ')' "
                    *ngFor="let image of workshopData.fields.additionalImages">

                </div>
            </div>

            <h3 id="workshop-name">{{workshopData.fields.name}}</h3>

            <div id="workshop-bar">
                <div class="bar-item-with-icon">
                    <img src="assets/images/calendar.svg" alt="">
                    <div>{{workshopData.fields.startTime| toDateDisplayString}}</div>
                </div>

                <div class="divider"></div>

                <div class="bar-item-with-icon">
                    <img src="assets/images/time.svg" alt="">
                    <div>{{workshopData.fields.startTime | toTimeStartEndDisplayString : workshopData.fields.endTime}}
                    </div>
                </div>
            </div>
        </div>

        <!-- workshop body -->
        <div class="workshop-body">
            <div class="column" [appRenderRichText]="workshopData.fields.content">

            </div>
        </div>

        <div class="booking-link-container" *ngIf="workshopData.fields.bookingLink">
            <a href="{{workshopData.fields.bookingLink}}">
                <button>Book Now</button>
            </a>
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { SectionHeadingComponent } from '../section-heading/section-heading.component';
import { RouterLink } from '@angular/router';
import { CardCarouselComponent } from '../card-carousel/card-carousel.component';
import { IFdinWorkshop } from '../interfaces/contentful';
import { WorkshopService } from '../../services/workshop.service';
import { Subject, takeUntil } from 'rxjs';
import { CardComponent } from '../card/card.component';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [SectionHeadingComponent, RouterLink, CardComponent, NgForOf, NgIf],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  public innovationDayCardsContent: IFdinWorkshop[] = [];
  public visibleInnovationDayCardsContentIndexes: number[] = [0, 1, 2];

  private componentDestroyed = new Subject<void>();

  constructor(private _WorkshopService: WorkshopService) {
    this.getInnovationDayDataContent();
  }

  getInnovationDayDataContent() {
    this._WorkshopService.getInnovationDayWorkshopsWorkshops(10).pipe(takeUntil(this.componentDestroyed)).subscribe((workshopsData) => {
      this.innovationDayCardsContent = workshopsData;
    });
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}

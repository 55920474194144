import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  @Input() text: string = "";
  @HostBinding('class') @Input() type: "error" | "info" = "error";
}

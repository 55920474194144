import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { map, Subject, takeUntil } from 'rxjs';
import { IFdinResourceLink } from '../interfaces/contentful';
import { ContentfulService } from '../services/contentful.service';
import { ResourceService } from '../../services/resource.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-header-bar',
  standalone: true,
  imports: [NgFor, NgForOf, NgIf, RouterModule, FormsModule],
  templateUrl: './header-bar.component.html',
  styleUrl: './header-bar.component.scss'
})
export class HeaderBarComponent {

  isAnyMenuItemOpen = false;
  isMobileMenuOpen = false;

  searchTerm = '';

  private componentDestroyed = new Subject<void>();

  menuItems: { text: string, isOpen?: boolean, items?: { text: string, link: string, isExternal?: boolean }[], link?: string, isExternal?: boolean }[] = [
    {
      text: "Chef Team", items: [
        { text: "Meet the Team", link: "/meet-the-team" },
        { text: "Junior Chef Program", link: "/article/the-innovation-chef-intern-program" },
        { text: "Contact Us", link: "/contact-us" }
      ]
    },
    { text: "Resources", link: "/resources", isExternal: false },
    { text: "Workshops", link: "/workshops", isExternal: false },
    {
      text: "Recipes", items: [
        { text: "Our Recipes", link: "/recipes" },
        { text: "Search All Recipes", link: "/recipes/search" },
        { text: "Own Brand", link: "/recipes/search/Own Brand" },
        { text: "Hacks", link: "/recipes/search/Hacks" }
      ]
    },
    {
      text: "Food Trends", items: [
        { text: "Food Trends", link: "articles/Food trend" },
        { text: "Food News", link: "articles/Food news" },
        { text: "New Products", link: "articles/New Products" },
        { text: "Social Media", link: "articles/Social Media" },
      ]
    }
  ];

  constructor(private router: Router, private _ResourceService: ResourceService) {
  }

  public openMenuItem(menuItem: any) {

    if (menuItem.link && menuItem.isExternal) {
      window.open(menuItem.link)
    } else if (menuItem.link && !menuItem.isExternal) {
      this.router.navigateByUrl(menuItem.link)
    } else if (!menuItem.link) {

      this.menuItems.forEach(item => {
        item.isOpen = false;
      });

      menuItem['isOpen'] = true;
      this.isAnyMenuItemOpen = true;
    }
  }

  public closeAllMenuItems() {
    this.menuItems.forEach(item => {
      item.isOpen = false;
    });

    this.isAnyMenuItemOpen = false;
  }

  public toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  public search(){
    this.isMobileMenuOpen = false;
    this.router.navigateByUrl('/search/' + this.searchTerm);
  }

  public navigateTo(route: { text: string, link: string, isExternal?: boolean }) {

    if(!route.isExternal){
      this.router.navigateByUrl(route.link);
    }else{
      window.open(route.link);
    }

    setTimeout(() => {
      this.closeAllMenuItems();
      this.isMobileMenuOpen = false;
    }, 50);
  }


  public toggleMenuItem(menuItem: any) {
    if (menuItem.link && menuItem.isExternal) {
      this.isMobileMenuOpen = false;
      window.open(menuItem.link)
    } else if (menuItem.link && !menuItem.isExternal) {
      this.isMobileMenuOpen = false;
      this.router.navigateByUrl(menuItem.link)
    } else if (!menuItem.link) {
      menuItem['isOpen'] = !menuItem['isOpen'];
    }
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}

<app-page-heading heading="Resources" breadcrumbText="Resources"></app-page-heading>

<div class="page-body-wrapper">
    <p *ngIf="resources?.length == 0 && !isLoading">There are no resources to show.</p>

    <p *ngIf="isLoading">Loading...</p>

    <ul *ngIf="!isLoading && resources && resources.length > 0">
        <a *ngFor="let resource of resources" [href]="resource.fields.resourceUrl">
            <li class="list-card">
                <div class="list-card-image"
                    [style.background-image]="resource.fields.image.fields.file.url ? 'url(' + resource.fields.image.fields.file.url + ')' : ''">
    
                </div>
                <div class="list-card-content">
                    <div class="list-card-date">
                        {{resource.sys.createdAt | date: 'dd MMM yyyy'}}
                    </div>
                    <div class="list-card-heading">
                        {{resource.fields.resourceName}}
                    </div>
                    <div class="list-card-body">
                        {{resource.fields.description}}
                    </div>
                    <button class="list-card-button">
                        View
                    </button>
                </div>
            </li>
        </a>
    </ul>
</div>
<app-page-heading heading="Recipe Details" breadcrumbText="Recipe details"></app-page-heading>

<div class="page-body-wrapper" id="recipe-content" *ngIf="recipeData">
    <div class="page-body-content">

        <!-- recipe header -->
        <div class="recipe-header">
            <!-- hero image -->
            <div class="page-hero-image"
                [style.background-size]="selectedHeroImageUrl == recipeData.fields.image.fields.file.url ? recipeData.fields.imageDisplayType : 'contain'"
                [style.background-image]="'url(' + selectedHeroImageUrl + ')' "></div>

            <div class="additional-images" *ngIf="recipeData.fields.additionalImages && recipeData.fields.additionalImages.length > 0">
                <div class="image-thumbnail" [class.selected]="selectedHeroImageUrl == recipeData.fields.image.fields.file.url" (click)="selectedHeroImageUrl = recipeData.fields.image.fields.file.url" [style.background-image]="'url(' + recipeData.fields.image.fields.file.url + ')' ">

                </div>
                <div class="image-thumbnail" [class.selected]="selectedHeroImageUrl == image.fields.file.url" (click)="selectedHeroImageUrl = image.fields.file.url" [style.background-image]="'url(' + image.fields.file.url + ')' " *ngFor="let image of recipeData.fields.additionalImages">

                </div>
            </div>

            <h3 id="recipe-name">{{recipeData.fields.heading}}</h3>
            <p id="recipe-subheading">
                {{recipeData.fields.description}}
            </p>

            <div id="recipe-bar">
                <div class="chip">{{recipeData.fields.mealType}}</div>

                <div class="bar-item-with-icon">
                    <img src="assets/images/time.svg" alt="">
                    <div>{{recipeData.fields.preparationTimeInMinutes | minutesToTime}}</div>
                </div>

                <div class="divider"></div>

                <div class="bar-item-with-icon">
                    <img src="assets/images/group.svg" alt="">
                    <div>Serves {{recipeData.fields.serves}}</div>
                </div>

                <!-- social -->
                <div class="social-links">
                    <button (click)="share('facebook')" aria-label="Share to Facebook">
                        <img src="assets/images/facebook-card-icon.svg" alt="">
                    </button>

                    <button (click)="share('pinterest')" aria-label="Share to pintrest">
                        <img src="assets/images/pinterest.svg" alt="">
                    </button>

                    <button (click)="share('mail')" aria-label="Share to mail">
                        <img src="assets/images/mail.svg" alt="">
                    </button>

                    <button (click)="share('print')" aria-label="print">
                        <img src="assets/images/print.svg" alt="">
                    </button>
                </div>
            </div>
        </div>

        <!-- recipe body -->
        <div class="recipe-body">
            <div class="column" [appRenderRichText]="recipeData.fields.leftContent">
            </div>

            <div class="column" [appRenderRichText]="recipeData.fields.rightContent">
            </div>
        </div>
    </div>
</div>
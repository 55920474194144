import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderBarComponent } from './shared/header-bar/header-bar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AuthorControlsComponent } from "./shared/components/author-controls/author-controls.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderBarComponent, FooterComponent, AuthorControlsComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'foodinnovators';
}

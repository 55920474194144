<app-page-heading heading="Our Recipes" breadcrumbText="Recipes"></app-page-heading>

<div class="page-body-wrapper">
    <div class="page-body-content-full-width">
        <app-video-player class="top-video-player" [isLinkExternal]="true" youtubeVideoId="byRn5KGhD2Q"
            title="Southern Fried Chicken" viewMoreLink="https://www.youtube.com/watch?v=byRn5KGhD2Q"
            viewMoreLinkText="See More On Youtube"
            previewImageUrl="assets/images/video-background.png"></app-video-player>
    </div>

    <div class="page-body-content">
        <app-section-heading heading="Get Cooking" subHeading="More Recipes"></app-section-heading>
    </div>

    <app-card-carousel [itemsLength]="moreRecipesCardsContent.length"
        (visibleItemIndexes)="visibleFeaturedRecipesIndexes = $event" *ngIf="moreRecipesCardsContent.length > 0">

        <app-card class="workshop-card" *ngFor="let index of visibleFeaturedRecipesIndexes"
            [cardImageUrl]="moreRecipesCardsContent[index].fields.image.fields.file.url"
            [cardHeading]="moreRecipesCardsContent[index].fields.heading"
            [cardSubHeading]="moreRecipesCardsContent[index].fields.mealType?.toString()"
            [cardBodyContent]="moreRecipesCardsContent[index].fields.description"
            [cardCTALink]="'/recipe/' + moreRecipesCardsContent[index].fields.slug" [cardCTAText]="'Get Cooking'"
            [isCTAButton]="true">

        </app-card>
    </app-card-carousel>


    <div class="page-body-content">
        <app-section-heading heading="Browse by type" subHeading="Meal"></app-section-heading>
    </div>

    <app-card-carousel [itemsLength]="mealTypeCardsContent.length"
        (visibleItemIndexes)="visibleMealTypesIndexes = $event">

        <app-card class="workshop-card" *ngFor="let index of visibleMealTypesIndexes"
            [cardImageUrl]="mealTypeCardsContent[index].imageUrl" [cardCTALink]="mealTypeCardsContent[index].link"
            [cardCTAText]="mealTypeCardsContent[index].ctaText" [isCTAButton]="true">

        </app-card>
    </app-card-carousel>

    <ng-content *ngIf="foodInnovatorFavouritesCardsContent.length > 3">
        <div class="page-body-content">
            <app-section-heading heading="Browse by type" subHeading="Food Innovator Favourites"></app-section-heading>
        </div>

        <app-card-carousel [itemsLength]="foodInnovatorFavouritesCardsContent.length"
            (visibleItemIndexes)="visibleFoodInnovatorFavouritesIndexes = $event">

            <app-card class="workshop-card" *ngFor="let index of visibleFoodInnovatorFavouritesIndexes"
                [cardImageUrl]="moreRecipesCardsContent[index].fields.image.fields.file.url"
                [cardHeading]="moreRecipesCardsContent[index].fields.heading"
                [cardSubHeading]="moreRecipesCardsContent[index].fields.mealType?.toString()"
                [cardBodyContent]="moreRecipesCardsContent[index].fields.description"
                [cardCTALink]="'/recipe/' + moreRecipesCardsContent[index].fields.slug" [cardCTAText]="'View Recipe'"
                [isCTAButton]="true">

            </app-card>
        </app-card-carousel>
    </ng-content>
</div>
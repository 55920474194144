import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IFdinResourceLink } from '../shared/interfaces/contentful';
import { ContentfulService } from '../shared/services/contentful.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(private cms: ContentfulService) {

  }

  /**
* Gets all resource links from the CMS.
*
* @param limit how many to fetch, if not provided, all are fetched
* @returns The results from the cms.
*/
  getAllResources(limit: number): Observable<IFdinResourceLink[]> {
    return this.cms.getContent('fdinResourceLink', { include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinResourceLink[]));
  }

  /**
* Search in the CMS
*
* @param searchTerm
* @param limit how many results to fetch, if not provided, all are fetched
* @returns The results from the cms.
*/
  searchResources(searchTerm: string, limit: number): Observable<IFdinResourceLink[]> {
    return this.cms.getContent('fdinResourceLink', { query: searchTerm, include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinResourceLink[]));
  }
}

import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { IFdinResourceLink } from '../../shared/interfaces/contentful';
import { ResourceService } from '../../services/resource.service';

@Component({
  selector: 'app-resources',
  standalone: true,
  imports: [PageHeadingComponent, NgFor, DatePipe, RouterLink, NgIf],
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss'
})
export class ResourcesComponent {
  public category?: string;

  isLoading = true;

  resources?: IFdinResourceLink[];


  private componentDestroyed = new Subject<void>();

  constructor(private _Activatedroute: ActivatedRoute, private _ResourceService: ResourceService, private _Router: Router) {
    this.getResources();
  }

  private getResources() {
    this._ResourceService.getAllResources(20).pipe(takeUntil(this.componentDestroyed)).subscribe((resourceData) => {
      this.resources = resourceData;

      this.isLoading = false;
    });
  }

   // Destroying the subscription on exit from the page
   ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}

import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [PageHeadingComponent, NgForOf],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {
  faqs: {heading: string, body: string}[] = [
    {
      "heading": "The class I want to attend is booked out is there any other classes?",
      "body": "Please view all our current advertised classes at http://foodinnovators.eventbrite.com/"
    },
    {
      "heading": "Do you hold classes on weekends?",
      "body": "Yes we sometimes do, please view all our current classes go to http://foodinnovators.eventbrite.com/ and follow us on facebook for the latest updates."
    },
    {
      "heading": "Can you tailor class to certain dietary requirements?",
      "body": "Yes some classes it is possbile other classes it is not pleae send us an email to enquire before you purchase any tickets."
    },
    {
      "heading": "Do you offer refunds?",
      "body": "No we dont offer refunds but we would be happy to transfer your ticket to another class with 12 or more hours notice prior to the class."
    },
    {
      "heading": "How do I redeem a gift voucher?",
      "body": "Gift vouchers can be redeemed via eventbrite. Enter the code on the bottom righthand corner of you voucher as a code when selecting tickets. If you have any difficulty at all, please do not hesitate to contact us at foodinnovators@woolworths.com.au"
    },
    {
      "heading": "How do I purchase a gift voucher?",
      "body": "Gift vouchers are available for purchase via the link on our website. In the unlikely event you strike some difficulty when purchasing a gift voucher, please don’t hesitate to email us (during standard business hours) and we will gladly assist you."
    },
    {
      "heading": "Can I bring a camera to the class?",
      "body": "You are welcome to bring a camera. We request that you use discretion and consider other participants privacy if taking photographs. Food Innovators reserves the right to revoke this permission at any time during a class, without notice and at its sole discretion."
    },
    {
      "heading": "Can I bring a friend to watch?",
      "body": "Sorry but no. Only confirmed guests that have paid for a place in a class may attend. You are most welcome to come with a friend or partner with you however they will need to purchase a ticket for their place in the class."
    },
    {
      "heading": "Do you provide the recipes?",
      "body": "Yes we will email you the reicpes after the class."
    },
    {
      "heading": "Do I need to bring anything to a class?",
      "body": "No need as we provide an apron to wear during the class. There is no need for you to bring anything. · Please wear appropriate and comfortable clothing with closed shoes."
    },
    {
      "heading": "Do you teach children’s cooking classes?",
      "body": "Yes, we strongly believe in teaching kids the right skills to confidently cook at home and provide a fun 2 hr class for them every school holidays."
    },
    {
      "heading": "What do I wear to my cooking class?",
      "body": "Please wear appropriate and comfortable clothing with closed shoes."
    },
    {
      "heading": "Do you have a mailing list?",
      "body": "We sure do and this is a great way to be kept up to date about new classes, special events, recipes and much more. You can sign up for our newsletter via our home page."
    },
    {
      "heading": "Do you hold Private cooking classes or Corporate team events?",
      "body": "Yes – we have a range of private classes that we can offer for all sorts of groups and occasions If you can’t find the information you need on our website please contact us and we’ll help you plan your very own class or team building event"
    }
  ];
}

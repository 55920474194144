import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ArticleService } from '../../services/article.service';
import { WorkshopService } from '../../services/workshop.service';
import { ResourceService } from '../../services/resource.service';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { NgFor, NgIf } from '@angular/common';
import { RecipeService } from '../../services/recipe.service';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [PageHeadingComponent, NgIf, NgFor, RouterLink],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {

  selectedSearchType: "article" | "recipe" | "resource" | "workshop" = "article";

  searchTerm: string = "";

  isLoading = true;

  results: { imageUrl: string, heading: string, description: string, link: string }[] = [];

  private componentDestroyed = new Subject<void>();

  constructor(private _Activatedroute: ActivatedRoute, private _Router: Router, private _ArticleService: ArticleService, private _WorkshopService: WorkshopService, private _ResourceService: ResourceService, private _RecipeService: RecipeService) {

  }

  ngOnInit() {
    //get the search term
    this._Activatedroute.params.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      this.isLoading = true;
      this.searchTerm = this.getSearchTermFromRoute();
      this.getSearchResults();
    });
    // get the results for the selected search type
  }

  changeSelectedSearchType(searchType: "article" | "recipe" | "resource" | "workshop") {
    this.selectedSearchType = searchType;
    this.isLoading = true;
    this.getSearchResults();
  }

  getSearchResults() {
    this.results = [];
    this.isLoading = true;

    switch (this.selectedSearchType) {
      case "article":
        this._ArticleService.searchArticles(this.searchTerm, 50).pipe(takeUntil(this.componentDestroyed)).subscribe((data) => {
          data.forEach(article => {
            this.results.push({ imageUrl: article.fields.heroImage.fields.file.url, heading: article.fields.title, description: article.fields.summary, link: '/article/' + article.fields.slug });
          })
          this.isLoading = false;
        });
        break;
      case "recipe":
        this._RecipeService.searchRecipes(this.searchTerm, 50).pipe(takeUntil(this.componentDestroyed)).subscribe((data) => {
          data.forEach(recipe => {
            this.results.push({ imageUrl: recipe.fields.image.fields.file.url, heading: recipe.fields.heading, description: recipe.fields.description, link: '/recipe/' + recipe.fields.slug });
          })
          this.isLoading = false;
        });
        break;
      case "resource":
        this._ResourceService.searchResources(this.searchTerm, 50).pipe(takeUntil(this.componentDestroyed)).subscribe((data) => {
          data.forEach(resource => {
            this.results.push({ imageUrl: resource.fields.image.fields.file.url, heading: resource.fields.resourceName, description: resource.fields.description, link: resource.fields.resourceUrl });
          })
          this.isLoading = false;
        });
        break;
      case "workshop":
        this._WorkshopService.searchWorkshops(this.searchTerm, 50).pipe(takeUntil(this.componentDestroyed)).subscribe((data) => {
          data.forEach(workshop => {
            this.results.push({ imageUrl: workshop.fields.image.fields.file.url, heading: workshop.fields.name, description: workshop.fields.shortDescription, link: '/workshop/' + workshop.fields.slug });
          })
          this.isLoading = false;
        });
        break;
    }
  }

  openLink(link: string){
    if(this.selectedSearchType == "resource"){
      window.location.href = link;
    }else{
      this._Router.navigateByUrl(link);
    }
  }

  /**
* The search term is in the route as a route param.
*/
  getSearchTermFromRoute(): string {
    // get the route param
    const searchParam = this._Activatedroute.snapshot.paramMap.get('searchTerm');

    if (searchParam) {
      return decodeURIComponent(searchParam);
    }else{
      return '';
    }
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { NgForOf, NgIf } from '@angular/common';
import { CardComponent } from '../../shared/card/card.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { RecipeService } from '../../services/recipe.service';
import { IFdinRecipe } from '../../shared/interfaces/contentful';
import { MinutesToTimePipe } from '../../shared/pipes/minutes-to-time.pipe';
import { RenderRichTextDirective } from '../../shared/directives/render-rich-text.directive';

@Component({
  selector: 'app-recipe',
  standalone: true,
  imports: [PageHeadingComponent, NgForOf, NgIf, CardComponent, CardCarouselComponent, SectionHeadingComponent, MinutesToTimePipe, RenderRichTextDirective],
  templateUrl: './recipe.component.html',
  styleUrl: './recipe.component.scss'
})
export class RecipeComponent implements OnInit, OnDestroy {

  public slug?: string; //slug of the recipe

  recipeData?: IFdinRecipe;

  public selectedHeroImageUrl?: string; 

  private componentDestroyed = new Subject<void>();

  constructor(private _Activatedroute: ActivatedRoute, private _RecipeService: RecipeService, private _Router: Router) {

  }

  ngOnInit() {
    this.slug = this.getSlugFromRoute();
    this.getRecipeData(this.slug);
  }

  /**
  * The slug of the recipe is in the route as a route param.
  */
  getSlugFromRoute(): string {
    // get the route param
    const slugParam = this._Activatedroute.snapshot.paramMap.get('recipeSlug');

    if(slugParam){
      return slugParam;
    }
    
    this._Router.navigateByUrl('/not-found');
    return '';
  }

  /**
     * Get data for the recipe.
     *
     * @param recipeSlug - slug for the recipe to return.
     */
  getRecipeData(recipeSlug: string) {
    this._RecipeService.getRecipeBySlug(recipeSlug).pipe(takeUntil(this.componentDestroyed)).subscribe((recipeData) => {
      if (!recipeData) {
        this._Router.navigateByUrl('/not-found');
        return;
      }

      this.recipeData = recipeData;
      this.selectedHeroImageUrl = recipeData.fields.image.fields.file.url; //pre-select the hero image

    });
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  share(method: 'print' | 'pinterest' | 'facebook' | 'mail'): void {
    const url = window.location.href;

    switch (method) {
      case 'print':
        window.print();
        break;
      case 'pinterest':
        window.open(`https://pinterest.com/pin/create/button/?url=${url}`, '_blank');
        break;
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
        break;
      case 'mail':
        window.location.href = `mailto:?subject=Check out this page&body=${url}`;
        break;
    }
  }

}

<button id="hideBtn" tabindex="-1" (click)="isExpanded = !isExpanded">
    <img src="assets/images/chevron-down-white.svg" alt="toggle author controls visibility" [class.isExpanded]="isExpanded">
</button>
<div>
    <div class="authorHeading">Author Controls</div>

    <div class="controlWrapper">
        <div>Preview Mode: {{cms.isPreviewModeEnabled ? "ENABLED" : "DISABLED"}}</div>
        <button (click)="enablePreview()" tabindex="-1" *ngIf="!cms.isPreviewModeEnabled">Enable Preview</button>
        <button (click)="disablePreview()" tabindex="-1" *ngIf="cms.isPreviewModeEnabled">Disable Preview</button>
    </div>    
</div>
import { Component } from '@angular/core';
import { IFdinWorkshop } from '../../shared/interfaces/contentful';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkshopService } from '../../services/workshop.service';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { RenderRichTextDirective } from '../../shared/directives/render-rich-text.directive';
import { NgFor, NgIf } from '@angular/common';
import { ToDateDisplayStringPipe } from "../../shared/pipes/to-date-display-string.pipe";
import { ToTimeStartEndDisplayStringPipe } from "../../shared/pipes/to-time-start-end-display-string.pipe";

@Component({
  selector: 'app-workshop',
  standalone: true,
  imports: [PageHeadingComponent, RenderRichTextDirective, NgIf, ToDateDisplayStringPipe, ToTimeStartEndDisplayStringPipe, NgFor],
  templateUrl: './workshop.component.html',
  styleUrl: './workshop.component.scss'
})
export class WorkshopComponent {

  public slug?: string; //slug of the workshop

  workshopData?: IFdinWorkshop;

  public selectedHeroImageUrl?: string; 

  private componentDestroyed = new Subject<void>();

  constructor(private _Activatedroute: ActivatedRoute, private _WorkshopService: WorkshopService, private _Router: Router) {

  }

  ngOnInit() {
    this.slug = this.getSlugFromRoute();
    this.getWorkshopData(this.slug);
  }

  /**
  * The slug of the workshop is in the route as a route param.
  */
  getSlugFromRoute(): string {
    // get the route param
    const slugParam = this._Activatedroute.snapshot.paramMap.get('workshopSlug');

    if (slugParam) {
      return slugParam;
    }

    this._Router.navigateByUrl('/not-found');
    return '';
  }

  /**
     * Get data for the workshop.
     *
     * @param workshopSlug - slug for the workshop to return.
     */
  getWorkshopData(workshopSlug: string) {
    this._WorkshopService.getWorkshopBySlug(workshopSlug).pipe(takeUntil(this.componentDestroyed)).subscribe((workshopData) => {
      if (!workshopData) {
        this._Router.navigateByUrl('/not-found');
        return;
      }

      this.workshopData = workshopData;
      this.selectedHeroImageUrl = workshopData.fields.image.fields.file.url; //pre-select the hero image

    });
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardSearchComponent } from '../../shared/card-search/card-search.component';
import { CardComponent } from '../../shared/card/card.component';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { IFdinRecipe } from '../../shared/interfaces/contentful';
import { NgFor, NgForOf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { RecipeService } from '../../services/recipe.service';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { MinutesToTimePipe } from '../../shared/pipes/minutes-to-time.pipe';

@Component({
  selector: 'app-recipes-search',
  standalone: true,
  imports: [CardSearchComponent, CardComponent, PageHeadingComponent, NgFor, NgForOf, FormsModule, MinutesToTimePipe],
  templateUrl: './recipes-search.component.html',
  styleUrl: './recipes-search.component.scss'
})
export class RecipesSearchComponent implements OnInit, OnDestroy {
  allSearchCardsContent: IFdinRecipe[] = [];

  searchCardsContentForSelectedCategory: IFdinRecipe[] = [];

  matchingSearchCardsContent: IFdinRecipe[] = [];

  recipeFilterOptions = ["All", "Baking", "Breakfast", "Dessert", "Dinner", "Lunch", "Salads", "Snacks", "Hacks", "Own Brand"];

  public category?: string; //the category

  private componentDestroyed = new Subject<void>();

  constructor(private _Activatedroute: ActivatedRoute, private _RecipeService: RecipeService) {

  }

  ngOnInit() {
    this._Activatedroute.params.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      this.category = this.getCategoryFromRoute();
      this.getStartingSearchCardsContent();
    });
  }

  getStartingSearchCardsContent() {
    this._RecipeService.getAllRecipes(100).pipe(takeUntil(this.componentDestroyed)).subscribe((recipeData) => {
      this.allSearchCardsContent = recipeData;
      this.filterCardsBasedOnCategory();
    });
  }

  getCategoryFromRoute(): string {
    // get the route param
    const categoryParam = this._Activatedroute.snapshot.paramMap.get('category');

    for (let categoryIndex = 0; categoryIndex < this.recipeFilterOptions.length; categoryIndex++) {
      const category = this.recipeFilterOptions[categoryIndex];

      if (category.toLowerCase() == categoryParam?.toLowerCase()) {
        return category;
      }

    }

    return "All";
  }

  filterCardsBasedOnCategory(){
    this.searchCardsContentForSelectedCategory = [];

    if(this.category == "All"){
      this.searchCardsContentForSelectedCategory = this.allSearchCardsContent;
      return;
    }

    this.allSearchCardsContent.forEach(card => {
      if(card.fields.mealType == this.category){
        this.searchCardsContentForSelectedCategory.push(card);
      }
    });
  }

  changeCategory(event: any){
    this.filterCardsBasedOnCategory();
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

}

import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ArticleService } from '../../services/article.service';
import { Subject, takeUntil } from 'rxjs';
import { IFdinArticle } from '../../shared/interfaces/contentful';
import { PageHeadingComponent } from "../../shared/page-heading/page-heading.component";
import { DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-articles',
  standalone: true,
  imports: [PageHeadingComponent, NgFor, DatePipe, RouterLink, NgIf],
  templateUrl: './articles.component.html',
  styleUrl: './articles.component.scss'
})
export class ArticlesComponent {

  public category?: string;

  isLoading = true;

  articles?: IFdinArticle[];


  private componentDestroyed = new Subject<void>();

  constructor(private _Activatedroute: ActivatedRoute, private _ArticleService: ArticleService, private _Router: Router) {

  }

  ngOnInit() {
    this._Activatedroute.params.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
      this.category = this.getCategoryFromRoute();
      this.getArticles(this.category);
    });
  }

  /**
* The category is in the route as a route param.
*/
  getCategoryFromRoute(): string {
    // get the route param
    const categoryParam = this._Activatedroute.snapshot.paramMap.get('category');

    if (categoryParam) {
      return categoryParam;
    }

    this._Router.navigateByUrl('/not-found');
    return '';
  }

  /**
   * Get articles for the given category
   *
   * @param category - category to return;
   */
  getArticles(category: string) {
    this._ArticleService.getArticlesByCategory(category).pipe(takeUntil(this.componentDestroyed)).subscribe((articles) => {

      this.articles = articles;
      this.isLoading = false;
    });
  }


  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }


}

import { Component, HostBinding } from '@angular/core';
import { ContentfulService } from '../../services/contentful.service';
import { environment } from '../../../../environments/environment';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-author-controls',
  standalone: true,
  imports: [NgIf],
  templateUrl: './author-controls.component.html',
  styleUrl: './author-controls.component.scss'
})
export class AuthorControlsComponent {
  @HostBinding('class.isExpanded') isExpanded: boolean = true;

  @HostBinding('class.available') isAuthorControlsAvailable: boolean = false;

  constructor(public cms: ContentfulService) {
    this.isAuthorControlsAvailable = this.getIsAuthorControlsAvailable(environment.urlToShowPreviewOptions);
    this.cms.isPreviewModeEnabled = this.isPreviewEnabledFromLocalStorage();
  }

  getIsAuthorControlsAvailable(urlToShowPreviewOptions: string): boolean {
    if (window.location.host === urlToShowPreviewOptions) {
      return true;
    }

    return false;
  }

  isPreviewEnabledFromLocalStorage(): boolean {
    const isPreviewEnabled = localStorage.getItem('isPreviewEnabled');

    if (isPreviewEnabled === 'true') {
      return true;
    }
    return false;
  }

  public enablePreview() {
    localStorage.setItem('isPreviewEnabled', 'true');
    window.location.reload();
  }

  public disablePreview() {
    localStorage.setItem('isPreviewEnabled', 'false');
    window.location.reload();
  }
}

<app-page-heading
  heading="Chef Team"
  breadcrumbText="Chef Team"
></app-page-heading>

<div class="page-body-wrapper">
    <div class="page-body-content">
        
        <!-- video -->
        <app-video-player
        youtubeVideoId="https://www.youtube.com/watch?v=RZ_0ImDYrPY"
        previewImageUrl="https://i.pinimg.com/736x/46/2d/a3/462da304e299554ccb15de97dd66c8a2.jpg"
        viewMoreLink="https://youtube.com"
        viewMoreLinkText="See More On Youtube" 
        previewImageUrl="assets/images/video-background.png"
        [isLinkExternal]="true"
        title="Let Us Show You"
        >

        </app-video-player>

    <!-- body columns -->
    <div class="container">
      <div class="column">
        <img src="assets/images/teamBuilding.png" alt="Team Building Image" />
      </div>
      <div class="column">
        <div class="card-heading">Team Building</div>
        <div class="card-content">
          Book our state of the art cooking school  & food theatre for a high
          energy, educational & totally uplifting team building challenge! Team
          building sessions are designed & tailored to your specific needs &
          outcomes and we have a number of options from like mystery box
          challenges to food innovation sprints or “The Amazing Food Race”. Chat
          to our team today to start the conversation on Sydney’s most inspiring
          team building sessions!
        </div>

        <div class="card-footer">
          <a [routerLink]="'/contact-us'" class="card-button">
            <button>Book a Team Building Session</button>
          </a>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="column">
        <div class="card-heading">Cooking School</div>
        <div class="card-content">
          Join us in our state of the art cooking school for a fun, informative
          & totally inspiring Workshop - enjoy a glass of wine while you watch
          out talented team of innovation chef’s demonstrating the recipes in
          our food theatre before getting hands on with the cooking yourself in
          our multi station cooking school, then sit down afterwards to enjoy
          the fruits of your labour with your new friends!
        </div>

        <div class="card-footer">
          <a [routerLink]="'/workshops'"class="card-button">
            <button>Learn More About Our Cooking School</button>
          </a>
        </div>
      </div>
      <div class="column">
        <img src="assets/images/cookingSchool.png" alt="Cooking School Image" />
      </div>
    </div>

    <div class="container">
      <img
        src="assets/images/innovationChefInternProgram.png"
        alt="The Innovation Chef Intern Program Image"
      />
      <div class="description">
        <div class="card-heading">The Innovation Chef Intern Program</div>
        <div class="card-content">
          Food Innovators launched Australia’s first Innovation Chef Intern
          Program in 2016.
        </div>

        <div class="card-content">
          The Innovation Chef Intern Program gives young Chef’s & Foodies the
          opportunity to work along side our Innovation Chef Team learning the
          nuts and bolts of what it means to develop new food products for
          retail. Click here to apply for our next intake!
        </div>

        <div class="card-footer">
          <a [routerLink]="'/contact-us'" class="card-button-green">
            <button>Apply for Next Intern Intake</button>
          </a>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="column">
        <div class="card-heading">Our Kitchens</div>
        <div class="card-content">
          Get in contact with us to book in one of our state of the art Kitchens
          for your next Supplier Demonstration
        </div>

        <div class="card-footer">
          <a [routerLink]="'/contact-us'" class="card-button">
            <button>Ask Us About Booking Our Kitchen</button>
          </a>
        </div>
      </div>
      <div class="column">
        <img src="assets/images/ourKitchens.png" alt="Our Kitchens Image" />
      </div>
    </div>
  </div>
</div>

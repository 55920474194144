<div class="page-body-content" id="innovation-days-section" *ngIf="innovationDayCardsContent.length >= 3">
    <app-section-heading heading="Innovation Days"></app-section-heading>

    <div id="innovation-cards">
        <app-card class="workshop-card" *ngFor="let index of visibleInnovationDayCardsContentIndexes"
            [cardImageUrl]="innovationDayCardsContent[index].fields.image.fields.file.url"
            [cardHeading]="innovationDayCardsContent[index].fields.name"
            [cardBodyContent]="innovationDayCardsContent[index].fields.shortDescription"
            [cardCTALink]="'/workshop/' + innovationDayCardsContent[index].fields.slug" [cardCTAText]="'More Info'"
            [isCTAButton]="false">

        </app-card>
    </div>
</div>


<div class="white-section">

    <!-- mosaic -->
    <div id="mosaic">
        <app-section-heading heading="Learn More"></app-section-heading>

        <div id="mosaic-columns-wrapper">
            <div class="mosaic-col">
                <div class="mosaic-card" id="mosaic-card-1" [routerLink]="'articles/Food trend'">
                    <div class="mosaic-card-image"
                        [style.background-image]="'url(' + 'assets/images/kitchen-inspiration-session.png' + ')' ">

                    </div>
                    <div class="mosaic-card-footer">
                        <div>
                            Trends
                        </div>
                        <img src="assets/images/arrow-right.svg" alt="">
                    </div>
                </div>
                <div class="mosaic-card" id="mosaic-card-2" [routerLink]="'/meet-the-team'">
                    <div class="mosaic-card-image"
                        [style.background-image]="'url(' + 'assets/images/chef-mosaic.png' + ')' ">

                    </div>
                    <div class="mosaic-card-footer">
                        <div>
                            Chef Team
                        </div>
                        <img src="assets/images/arrow-right.svg" alt="">
                    </div>
                </div>
            </div>

            <div class="mosaic-col">
                <div class="mosaic-card" id="mosaic-card-3" [routerLink]="'/workshops'">
                    <div class="mosaic-card-image"
                        [style.background-image]="'url(' + 'assets/images/cooking-school.png' + ')' ">

                    </div>
                    <div class="mosaic-card-footer">
                        <div>
                            Workshops
                        </div>
                        <img src="assets/images/arrow-right.svg" alt="">
                    </div>
                </div>
                <div class="mosaic-card" id="mosaic-card-4" [routerLink]="'/recipes'">
                    <div class="mosaic-card-image"
                        [style.background-image]="'url(' + 'assets/images/what-we-do.png' + ')' ">

                    </div>
                    <div class="mosaic-card-footer">
                        <div>
                            Recipes
                        </div>
                        <img src="assets/images/arrow-right.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- cta -->
    <div id="mailing-list-cta">
        <h2>Newsletter coming soon!</h2>
        <p>Keep an eye out for more information about our newsletter!</p>
    </div>

    <!-- footer links -->
    <div id="footer-links">

        <div class="footer-column" id="logo-column">
            <img src="assets/images/logo.png" alt="" class="fi-logo">
        </div>
        <div class="footer-column">
            <div class="column-heading">Menu</div>
            <ul>
                <li>
                    <a routerLink="/meet-the-team">
                        Chef Team
                    </a>
                </li>
                <li>
                    <a href="https://teamspace.woolworths.com.au/s/innovation-centre-bookings">
                        Book a Kitchen
                    </a>
                </li>
                <li>
                    <a routerLink="/resources">
                        Resources
                    </a>
                </li>
                <li>
                    <a routerLink="/articles/Food trend">
                        Food Trends
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer-column">
            <div class="column-heading">Other Links</div>
            <ul>
                <li>
                    <a [routerLink]="'faq'">
                        FAQ
                    </a>
                </li>
                <li>
                    <a routerLink="/article/the-innovation-chef-intern-program">
                        Junior Innovation Chef Program
                    </a>
                </li>
                <li>
                    <a routerLink="/workshops">
                        Workshops
                    </a>
                </li>
                <li>
                    <a [routerLink]="'contact-us'">
                        Contact Us
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer-column social-column">
            <div class="column-heading">Connect With Us</div>

            <div id="social-media-icons">
                <a href="https://www.instagram.com/innovatorsfood/">
                    <img src="assets/images/instagram.svg" alt="Instagram">
                </a>

                <a href="https://www.youtube.com/@foodinnovators5997">
                    <img src="assets/images/youtube.svg" alt="Youtube">
                </a>
            </div>

            <div class="address">
                <div>1 Woolworths Way</div>
                <div>1 Woolworths Way, Bella Vista NSW 2153, Australia</div>
            </div>

            <button [routerLink]="'/contact-us'">
                <span>Contact Us</span>
                <img src="assets/images/mail-icon.svg" alt="">
            </button>
        </div>

    </div>

    <!-- footer bar -->
    <div id="bottom-bar">
        <div>
            © Woolworths Ltd. 2025. All rights reserved. ABN 88 000 014 675 
        </div>

        <div>
            <!-- <a href="">
                Terms and Policy
            </a> -->
        </div>
    </div>
</div>
import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-what-we-do',
  standalone: true,
  imports: [PageHeadingComponent, VideoPlayerComponent, RouterLink],
  templateUrl: './what-we-do.component.html',
  styleUrl: './what-we-do.component.scss'
})
export class WhatWeDoComponent {

}

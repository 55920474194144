<div id="search-inputs">

    <!-- search input -->
    <form (ngSubmit)="cardsShownCount = 6; filterResults()">
        <label>
            <span>Search</span>
            <input type="text" [(ngModel)]="searchTerm" name="searchTerm">
        </label>
        <button class="button">
            <span>
                Search
            </span>
            <img src="assets/images/search-white.svg" alt="">
        </button>
    </form>

    <!-- custom filters -->
    <div id="custom-filter-container">
        <ng-content select=".custom-filter">
        </ng-content>
    </div>
</div>
<div id="results-container">
    <app-alert *ngIf="matchingCards.length == 0" text="Nothing to show!" type="info"></app-alert>

    <ng-content select=".results">
    </ng-content>
</div>
<div id="show-more-container" *ngIf="showMoreButton">
    <button (click)="increaseShownCount()">
        Show More
    </button>
</div>
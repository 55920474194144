import { Injectable } from '@angular/core';
import { ContentfulService } from '../shared/services/contentful.service';
import { map, Observable } from 'rxjs';
import { IFdinWorkshop } from '../shared/interfaces/contentful';

@Injectable({
  providedIn: 'root'
})
export class WorkshopService {

  constructor(private cms: ContentfulService) {

  }

  /**
   * Gets a workshop from the CMS.
   *
   * @param slug - slug of the workshop to retrieve.
   * @returns The workshop from the cms.
   */
  getWorkshopBySlug(slug: string | number): Observable<IFdinWorkshop> {
    return this.cms.getContent('fdinWorkshop', { 'fields.slug': slug, include: 10 }).pipe(map((res) => res[0] as IFdinWorkshop));
  }

  /**
  * Gets all workshops from the CMS.
  *
  * @param limit how many workshops to fetch, if not provided, all are fetched
  * @returns The workshops from the cms.
  */
  getAllWorkshops(limit: number): Observable<IFdinWorkshop[]> {
    return this.cms.getContent('fdinWorkshop', { include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinWorkshop[]));
  }

  /**
* Gets all workshops with innovationday = true from the CMS.
*
* @param limit how many workshops to fetch, if not provided, all are fetched
* @returns The workshops from the cms.
*/
  getInnovationDayWorkshopsWorkshops(limit: number): Observable<IFdinWorkshop[]> {
    return this.cms.getContent('fdinWorkshop', { 'fields.showAsInnovationDay': 'true', include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinWorkshop[]));
  }

  /**
* Search in the CMS
*
* @param searchTerm
* @param limit how many results to fetch, if not provided, all are fetched
* @returns The results from the cms.
*/
  searchWorkshops(searchTerm: string, limit: number): Observable<IFdinWorkshop[]> {
    return this.cms.getContent('fdinWorkshop', { query: searchTerm, include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinWorkshop[]));
  }
}

<div class="card-background" [style.background-image]="'url(' + cardImageUrl + ')' " (click)="goToLink()">

</div>
<div class="card-body">

    <!-- social links and tags -->
    <div class="card-social-and-tags" *ngIf="cardTag || showSocialLinks">
        <div class="card-tag" *ngIf="cardTag">
            {{cardTag}}
        </div>

        <div class="social-links" *ngIf="showSocialLinks">
            <button (click)="share('facebook')" aria-label="Share to Facebook">
                <img src="assets/images/facebook-card-icon.svg" alt="">
            </button>

            <button (click)="share('pinterest')" aria-label="Share to pintrest">
                <img src="assets/images/pinterest.svg" alt="">
            </button>

            <button (click)="share('mail')" aria-label="Share to mail">
                <img src="assets/images/mail.svg" alt="">
            </button>

            <button (click)="share('print')" aria-label="print">
                <img src="assets/images/print.svg" alt="">
            </button>
        </div>
    </div>

    <!-- bar -->
    <div class="card-bar" *ngIf="barContent && barContent.length && barContent.length > 0">
        <div class="card-item" *ngFor="let item of barContent">
            <img [src]="item.iconImageUrl" alt="">
            <div>
                {{item.text}}
            </div>
        </div>
    </div>

    <!-- body -->
    <div class="card-body-content">
        <div class="card-heading" *ngIf="cardHeading" [class.dontTruncate]="dontTruncateText">
            {{cardHeading}}
        </div>
        <div class="card-sub-heading" *ngIf="cardSubHeading" [class.dontTruncate]="dontTruncateText">
            {{cardSubHeading}}
        </div>
        <div class="card-content" *ngIf="cardBodyContent" [class.dontTruncate]="dontTruncateText">
            {{cardBodyContent}}
        </div>

        <!-- footer with link or button -->
        <div class="card-footer">
            <div class="card-link" *ngIf="cardCTALink && cardCTAText && !isCTAButton">
                <a href="{{cardCTALink}}">
                    {{cardCTAText}}
                </a>

                <img src="assets/images/green-arrow-left.svg" alt="">
            </div>

            <a href="{{cardCTALink}}" class="card-button" *ngIf="cardCTALink && cardCTAText && isCTAButton">
                <button>
                    {{cardCTAText}}
                </button>
            </a>
        </div>

    </div>

</div>
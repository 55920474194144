import { Component } from '@angular/core';
import { VideoPlayerComponent } from '../../shared/video-player/video-player.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { NgFor, NgIf } from '@angular/common';
import { CardComponent } from '../../shared/card/card.component';
import { Subject, takeUntil } from 'rxjs';
import { WorkshopService } from '../../services/workshop.service';
import { IFdinWorkshop } from '../../shared/interfaces/contentful';
import { ToDateDisplayStringPipe } from "../../shared/pipes/to-date-display-string.pipe";
import { ToTimeStartEndDisplayStringPipe } from "../../shared/pipes/to-time-start-end-display-string.pipe";
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [VideoPlayerComponent, SectionHeadingComponent, CardCarouselComponent, CardComponent, NgFor, ToDateDisplayStringPipe, ToTimeStartEndDisplayStringPipe, NgIf, RouterLink],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  public visibleWorkshopCardsContentIndexes: number[] = [];

  public workshopCardsContent: IFdinWorkshop[] = [];

  private componentDestroyed = new Subject<void>();

  constructor(private _WorkshopService: WorkshopService) {
    this.getWorkshopsDataContent();
  }

  getWorkshopsDataContent() {
    this._WorkshopService.getAllWorkshops(100).pipe(takeUntil(this.componentDestroyed)).subscribe((workshopsData) => {
      this.workshopCardsContent = workshopsData;
    });
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

}

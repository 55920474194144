import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WorkshopsComponent } from './pages/workshops/workshops.component';
import { WhatWeDoComponent } from './pages/what-we-do/what-we-do.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MeetTheTeamComponent } from './pages/meet-the-team/meet-the-team.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { RecipeComponent } from './pages/recipe/recipe.component';
import { RecipesComponent } from './pages/recipes/recipes.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RecipesSearchComponent } from './pages/recipes-search/recipes-search.component';
import { WorkshopComponent } from './pages/workshop/workshop.component';
import { ArticleComponent } from './pages/article/article.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { ArticlesComponent } from './pages/articles/articles.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { SearchComponent } from './pages/search/search.component';

export const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'workshops', component: WorkshopsComponent},
    {path: 'workshop/:workshopSlug', component: WorkshopComponent},
    {path: 'recipe/:recipeSlug', component: RecipeComponent},
    {path: 'articles/:category', component: ArticlesComponent},
    {path: 'resources', component: ResourcesComponent},
    {path: 'article/:articleSlug', component: ArticleComponent},
    {path: 'search/:searchTerm', component: SearchComponent},
    {path: 'recipes', component: RecipesComponent},
    {path: 'recipes/search/:category', component: RecipesSearchComponent},
    {path: 'recipes/search', component: RecipesSearchComponent},
    {path: 'contact-us', component: ContactUsComponent},
    {path: 'meet-the-team', component: MeetTheTeamComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'chef-team', component: WhatWeDoComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'not-found', component: NotFoundComponent},
    {path: '**', component: NotFoundComponent},
];
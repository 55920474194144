import { Component } from '@angular/core';
import { PageHeadingComponent } from '../../shared/page-heading/page-heading.component';
import { AlertComponent } from '../../shared/alert/alert.component';
import { SectionHeadingComponent } from '../../shared/section-heading/section-heading.component';
import { CardCarouselComponent } from '../../shared/card-carousel/card-carousel.component';
import { CardComponent } from '../../shared/card/card.component';
import { CommonModule, NgForOf } from '@angular/common';
import { CardSearchComponent } from '../../shared/card-search/card-search.component';
import { FormsModule } from '@angular/forms';
import { IFdinWorkshop } from '../../shared/interfaces/contentful';
import { WorkshopService } from '../../services/workshop.service';
import { Subject, takeUntil } from 'rxjs';
import { ToDateDisplayStringPipe } from "../../shared/pipes/to-date-display-string.pipe";
import { ToTimeStartEndDisplayStringPipe } from "../../shared/pipes/to-time-start-end-display-string.pipe";

@Component({
  selector: 'app-workshops',
  standalone: true,
  imports: [PageHeadingComponent, AlertComponent, SectionHeadingComponent, CardCarouselComponent, CardComponent, NgForOf, CardSearchComponent, FormsModule, ToDateDisplayStringPipe, ToTimeStartEndDisplayStringPipe, CommonModule],
  templateUrl: './workshops.component.html',
  styleUrl: './workshops.component.scss'
})
export class WorkshopsComponent {

  public visiblePastWorkshopCardsContentIndexes: number[] = [];

  public category: "upcoming" | "past" | "all" = "upcoming";

  public pastWorkshopCardsContent: IFdinWorkshop[] = [];

  public allWorkshopsSearchCardsContent: IFdinWorkshop[] = [];

  public workshopsSearchCardsContentForSelectedCategory: IFdinWorkshop[] = [];

  public matchingWorkshopsSearchCardsContent: IFdinWorkshop[] = [];

  private componentDestroyed = new Subject<void>();

  constructor(private _WorkshopService: WorkshopService) {

  }

  async ngOnInit() {
    this.getWorkshopsDataContent();
  }

  getWorkshopsDataContent() {
    this._WorkshopService.getAllWorkshops(100).pipe(takeUntil(this.componentDestroyed)).subscribe((workshopsData) => {
      this.allWorkshopsSearchCardsContent = workshopsData;
      this.filterCardsBasedOnCategory();

      let pastWorkshops: IFdinWorkshop[] = [];

      workshopsData.forEach(workshop => {
        if (new Date(workshop.fields.startTime) < new Date()) {
          pastWorkshops.push(workshop);
        }
      });
      
  
      this.pastWorkshopCardsContent = pastWorkshops;
    });
  }



  changeCategory(event: any) {
    this.filterCardsBasedOnCategory();
  }

  filterCardsBasedOnCategory() {
    this.workshopsSearchCardsContentForSelectedCategory = [];

    if (this.category == "all") {
      this.workshopsSearchCardsContentForSelectedCategory = this.allWorkshopsSearchCardsContent;
      return;
    }

    if (this.category == "upcoming") {
      this.allWorkshopsSearchCardsContent.forEach(card => {
        if (new Date(card.fields.startTime) > new Date()) {
          this.workshopsSearchCardsContentForSelectedCategory.push(card);
        }
      });
    } else if (this.category == "past") {
      this.allWorkshopsSearchCardsContent.forEach(card => {
        if (new Date(card.fields.startTime) < new Date()) {
          this.workshopsSearchCardsContentForSelectedCategory.push(card);
        }
      });
    }
  }

  // Destroying the subscription on exit from the page
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }
}

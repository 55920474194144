<app-page-heading heading="Recipes" breadcrumbText="Recipes"></app-page-heading>

<div class="page-body-wrapper">

    <div class="page-body-content">

        <app-card-search (matchingCardsEvent)="matchingSearchCardsContent = $event" [cards]="searchCardsContentForSelectedCategory">

            <div class="results">
                <app-card class="recipe-card" *ngFor="let card of matchingSearchCardsContent"
                    [cardImageUrl]="card.fields.image.fields.file.url" [cardHeading]="card.fields.heading" [cardBodyContent]="card.fields.description"
                    [cardCTALink]="'/recipe/' + card.fields.slug" [cardCTAText]="'View Recipe'" [isCTAButton]="false"
                    [showSocialLinks]="true"
                    [cardTag]="'' + card.fields.mealType"
                    [barContent]="[{iconImageUrl: '/assets/images/time.svg', text: (card.fields.preparationTimeInMinutes | minutesToTime )}, {iconImageUrl: '/assets/images/group.svg', text: 'Serves ' + card.fields.serves}]">

                </app-card>
            </div>

            <div class="custom-filter">
                <label>
                    <span>Recipes</span>
                    <select name="recipe-filter" id="recipe-filter" [(ngModel)]="category" (change)="changeCategory($event)">
                        <option *ngFor="let option of recipeFilterOptions" [value]="option">{{option}}</option>
                    </select>
                </label>
            </div>
        </app-card-search>
    </div>

</div>
<app-page-heading heading="Workshops" breadcrumbText="Workshops"></app-page-heading>

<div class="page-body-wrapper">
    <div class="page-body-content">
        <app-alert
            text="Due to COVID 19 our Workshops are on currently on hold. We hope to reopen in the future. Please follow us on facebook for the latest updates!"></app-alert>
        <p class="text-center">
            Join us in our state of the art cooking school for a fun, informative & totally inspiring Workshop - enjoy a
            glass of wine while you watch out talented team of innovation chef’s demonstrating the recipes in our food
            theatre before getting hands on with the cooking yourself in our multi station cooking school, then sit down
            afterwards to enjoy the fruits of your labour with your new friends!
        </p>

    </div>
    <div class="page-body-content">
        <app-section-heading [heading]="'Search Workshops'"></app-section-heading>

        <app-card-search (matchingCardsEvent)="matchingWorkshopsSearchCardsContent = $event"
            [cards]="workshopsSearchCardsContentForSelectedCategory">

            <div class="custom-filter">
                <label>
                    <span>Workshops</span>
                    <select name="recipe-filter" id="recipe-filter" [(ngModel)]="category"
                        (change)="changeCategory($event)">
                        <option value="upcoming">Upcoming Workshops</option>
                        <option value="past">Past Workshops</option>
                        <option value="all">All Workshops</option>
                    </select>
                </label>
            </div>

            <div class="results">
                <app-card class="workshop-card" *ngFor="let card of matchingWorkshopsSearchCardsContent"
                    [cardImageUrl]="card.fields.image.fields.file.url" [cardHeading]="card.fields.name"
                    [cardBodyContent]="card.fields.shortDescription" [cardCTALink]="'/workshop/' + card.fields.slug"
                    [cardCTAText]="'More Info'" [isCTAButton]="false"
                    [barContent]="[{iconImageUrl: '/assets/images/calendar.svg', text: (card.fields.startTime | toDateDisplayString) }, {iconImageUrl: '/assets/images/time.svg', text: card.fields.startTime | toTimeStartEndDisplayString : card.fields.endTime}]">

                </app-card>
            </div>

        </app-card-search>
    </div>

    <div class="page-body-content">
        <app-section-heading [heading]="'Check Out Some of Our Past Workshops Below'"></app-section-heading>
    </div>

    <div class="page-body-content-full-width">
        <app-card-carousel *ngIf="pastWorkshopCardsContent.length > 0" [itemsLength]="pastWorkshopCardsContent.length"
            (visibleItemIndexes)="visiblePastWorkshopCardsContentIndexes = $event">
            <app-card class="workshop-card" *ngFor="let index of visiblePastWorkshopCardsContentIndexes"
                [cardImageUrl]="pastWorkshopCardsContent[index].fields.image.fields.file.url"
                [cardHeading]="pastWorkshopCardsContent[index].fields.name"
                [cardBodyContent]="pastWorkshopCardsContent[index].fields.shortDescription"
                [cardCTALink]="'/workshop/' + pastWorkshopCardsContent[index].fields.slug" [cardCTAText]="'More Info'"
                [isCTAButton]="false"
                [barContent]="[{iconImageUrl: '/assets/images/calendar.svg', text: (pastWorkshopCardsContent[index].fields.startTime | toDateDisplayString) }, {iconImageUrl: '/assets/images/time.svg', text: pastWorkshopCardsContent[index].fields.startTime | toTimeStartEndDisplayString : pastWorkshopCardsContent[index].fields.endTime}]">

            </app-card>
        </app-card-carousel>

    </div>
</div>
<div class="full-screen">
    <img src="assets/images/logo-with-shadow.png" alt="logo">
    <h2>Our new site is launching on the 3rd of February!</h2>
    <p>In the meantime, click the button below to continue to kitchen booking.</p>

    <a href="https://teamspace.woolworths.com.au/s/innovation-centre-bookings">
        <button>
            Book a kitchen
        </button>
    </a>
</div>
<div id="landing">
    <div id="landing-content">
        <h1>Inspiring the Future of Food</h1>
        <p>
            Food Innovators are Woolworth's dynamic & talented team of in house Innovation Chef's.
        </p>
        <p>
            We are inspired and driven by our love of good food. We are continually researching new food trends,
            developing new recipes and finding new ingredients to create inspiring new food products for you.
        </p>
        <p>
            Enjoy our new online home & get inspired & informed with recipes, the latest food trends & food stories!
        </p>
        
        <a href="https://teamspace.woolworths.com.au/s/innovation-centre-bookings">
            <button class="button">
                Book A Kitchen
            </button>
        </a>
    </div>

    <div id="landing-photo">
        <img src="assets/images/food-hero.png" alt="">
    </div>
</div>

<app-video-player class="home-video-player" [isLinkExternal]="true" youtubeVideoId="SJgZPsC7470" title="Take a look at our Team Building Cooking Sessions" viewMoreLink="https://www.youtube.com/watch?v=SJgZPsC7470" viewMoreLinkText="See More On Youtube" previewImageUrl="assets/images/video-background.png"></app-video-player>

<app-section-heading *ngIf="workshopCardsContent.length > 0" heading="LEARN ESSENTIAL SKILLS" subHeading="Workshops"></app-section-heading>

<app-card-carousel *ngIf="workshopCardsContent.length > 0" [itemsLength]="workshopCardsContent.length"
    (visibleItemIndexes)="visibleWorkshopCardsContentIndexes = $event">
    <app-card class="workshop-card" *ngFor="let index of visibleWorkshopCardsContentIndexes"
        [cardImageUrl]="workshopCardsContent[index].fields.image.fields.file.url"
        [cardHeading]="workshopCardsContent[index].fields.name"
        [cardBodyContent]="workshopCardsContent[index].fields.shortDescription"
        [cardCTALink]="'/workshop/' + workshopCardsContent[index].fields.slug" [cardCTAText]="'More Info'" [isCTAButton]="false"
        [barContent]="[{iconImageUrl: '/assets/images/calendar.svg', text: (workshopCardsContent[index].fields.startTime | toDateDisplayString) }, {iconImageUrl: '/assets/images/time.svg', text: workshopCardsContent[index].fields.startTime | toTimeStartEndDisplayString : workshopCardsContent[index].fields.endTime}]">

    </app-card>
</app-card-carousel>

<a [routerLink]="'/workshops'" *ngIf="workshopCardsContent.length > 0" class="see-more-button">
    <button>
        <img src="assets/images/plus.svg" alt="">
        <span>See More Workshops</span>
    </button>
</a>
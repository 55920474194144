import { Injectable } from '@angular/core';
import { ContentfulService } from '../shared/services/contentful.service';
import { map, Observable } from 'rxjs';
import { IFdinArticle } from '../shared/interfaces/contentful';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private cms: ContentfulService) {

  }

  /**
 * Gets an article from the CMS.
 *
 * @param slug - slug of the article to retrieve.
 * @returns The article from the cms.
 */
  getArticleBySlug(slug: string | number): Observable<IFdinArticle> {
    return this.cms.getContent('fdinArticle', { 'fields.slug': slug, include: 10 }).pipe(map((res) => res[0] as IFdinArticle));
  }

  /**
* Gets all articles in a given category
*
* @param category - category of the article to retrieve.
* @returns The article from the cms.
*/
  getArticlesByCategory(category: string | number): Observable<IFdinArticle[]> {
    return this.cms.getContent('fdinArticle', { 'fields.category': category, order: '-sys.createdAt', include: 10 }).pipe(map((res) => res as IFdinArticle[]));
  }


  /**
* Gets all articles from the CMS.
*
* @param limit how many articles to fetch, if not provided, all are fetched
* @returns The articles from the cms.
*/
  getAllArticles(limit: number): Observable<IFdinArticle[]> {
    return this.cms.getContent('fdinArticle', { include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinArticle[]));
  }

/**
* Search for an article in the CMS
*
* @param searchTerm
* @param limit how many articles to fetch, if not provided, all are fetched
* @returns The articles from the cms.
*/
searchArticles(searchTerm: string, limit: number): Observable<IFdinArticle[]> {
  return this.cms.getContent('fdinArticle', { query: searchTerm, include: 10, limit: limit ? limit : 1000 }).pipe(map((res) => res as IFdinArticle[]));
}
}
